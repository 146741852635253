import { getCDNUrl } from "@/lib/cdn"
import HologramEmbed from "components/embed/HologramEmbed"
import { EmbedHologram } from "lib/hologramProps"
import { getThumbnail } from "lib/utils.hologram"
import { MutableRefObject, useEffect, useRef } from "react"

interface HeroProps {
	heroHologram: EmbedHologram
	wiggleContainer?: MutableRefObject<any>
}
export default function HeroImageToHologram(props: HeroProps) {
	const hologram = props.heroHologram

	const thumbnail = hologram && getThumbnail(hologram, 2000)
	const blurredPreviewUrl = thumbnail && getCDNUrl(thumbnail.url, { blur: 20 })

	const mainDivRef = useRef<HTMLDivElement>(null)

	// Use useEffect to apply the background image when the component mounts or the blurredPreview.url changes
	useEffect(() => {
		if (mainDivRef.current && blurredPreviewUrl) {
			mainDivRef.current.style.backgroundImage = `url(${blurredPreviewUrl})`
			mainDivRef.current.style.backgroundSize = "cover"
			mainDivRef.current.style.backgroundRepeat = "no-repeat"
			mainDivRef.current.style.backgroundPosition = "center"
		}
	}, [blurredPreviewUrl])

	return (
		<div className={`w-full`} ref={mainDivRef}>
			{/* darken the background to improve text legibility */}
			<div className={"bg-black bg-opacity-[35%] py-8"}>
				<div
					className={
						"mx-auto items-center md:grid md:max-w-7xl md:grid-cols-2 md:gap-16 md:space-y-0 md:px-8"
					}>
					<div className="mx-auto max-w-4xl space-y-4 text-left">
						<h2 className="bg-text holo5 max-w-4xl px-8 text-left font-rubik text-3xl font-black md:px-0 md:text-left md:text-5xl">
							Dive into Dimensions: <br /> 3D awaits you.
						</h2>
						<p className={"max-w-lg px-8 pb-4 text-left text-base md:px-0"}>
							Instantly add a new dimension to your art, photographs, stills and more. Upload any image and
							within seconds, Blocks generates a detailed depth map and hologram that you can share with the
							world.
						</p>
					</div>

					<HologramEmbed
						hologram={props.heroHologram}
						dropshadow={false}
						enableVR={false}
						wiggle={true}
						enableOnLookingGlass={false}
						enableIntersectionObserver={false}
						enableAngleStore={false}
						animate={false}
						// showTitle={true}
						// showCreator={true}
						fullscreen={true}
						className="relative z-[3] h-full w-full p-8 md:h-[490px] md:max-w-sm md:p-0"
						wiggleContainerRef={props.wiggleContainer}
					/>
				</div>
			</div>
		</div>
	)
}
