import { EmbedHologram } from "lib/hologramProps"
import React, { RefObject, useEffect, useId, useState, useRef, useCallback } from "react"
import PreviewCard, { PreviewCardArgs } from "./PreviewCard/PreviewCard"
import { useVirtualizer, useWindowVirtualizer } from "@tanstack/react-virtual"
import { HOLOGRAM_DEFAULT_ASPECT_RATIO } from "@/prisma/models"

export interface HologramGalleryArgs {
	holograms: EmbedHologram[]
	selectable?: boolean
	hasNextPage?: boolean
	fetchNextPage: any
	isFetchingNextPage: boolean
	card?: Omit<PreviewCardArgs, "hologram">
	selection?: Set<number>
	onSelect?: (id: number) => any
	playlistItemIDs?: number[]
	scrollRef?: RefObject<HTMLDivElement>
}

export default function HologramGallery(args: HologramGalleryArgs) {
	const {
		holograms = [],
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		selectable = false,
		card,
		playlistItemIDs,
		onSelect,
		selection,
		scrollRef,
	} = args
	const uid = useId()

	const galleryRef = useRef<HTMLDivElement | null>(null)

	// Assumes the masonry container is full page width
	let breakpoints: { [key: number]: number } = {
		640: 2, // sm
		768: 2, // md
		1024: 3, // lg
		1280: 4, // xl
		1536: 5, // 2xl
	}

	const columns = galleryRef.current?.clientWidth
		? Object.entries(breakpoints).reduce<number>(
				//@ts-ignore
				(closest, [breakpoint, cols]) => (galleryRef.current?.clientWidth >= +breakpoint ? cols : closest),
				2,
			)
		: 2

	const [animatedGrams, setAnimatedGrams] = useState<number[]>([])

	// Randomly pick holograms to force animate
	useEffect(() => {
		if (holograms && card?.autoplay) {
			const interval = 7
			const total = Math.round(holograms.length / interval)
			const grams: number[] = []
			for (let i = 0; i < total; i++) {
				grams.push(holograms[i * interval].id!)
			}
			setAnimatedGrams(grams)
		}
	}, [holograms?.length, card?.autoplay])

	const isSelected = (index: number) => {
		if (!selectable || !selection) return
		if (playlistItemIDs) {
			return selection.has(playlistItemIDs[index])
		} else return selection.has(holograms[index].id)
	}

	const handleOnSelect = (index: number) => {
		if (!selectable || !selection || !onSelect) return

		if (playlistItemIDs) {
			return onSelect(playlistItemIDs[index])
		} else {
			return onSelect(holograms[index].id)
		}
	}

	const estimateSize = useCallback(
		(i) => {
			if (!galleryRef.current) return 450
			const width = galleryRef.current.clientWidth / columns
			const hologram = holograms[i]
			const height = width / (hologram?.aspectRatio ?? HOLOGRAM_DEFAULT_ASPECT_RATIO) + 10 // 10px padding
			return height
		},
		[galleryRef, columns],
	)

	const windowVirtualizer = useWindowVirtualizer({
		count: hasNextPage ? holograms.length + 1 : holograms.length,
		estimateSize,
		overscan: 5,
		lanes: columns,
		scrollMargin: galleryRef.current?.offsetTop ?? 0,
		enabled: !scrollRef?.current,
	})

	const screenVirtualizer = useVirtualizer({
		count: hasNextPage ? holograms.length + 1 : holograms.length,
		getScrollElement: () => scrollRef?.current ?? null,
		estimateSize,
		overscan: 4,
		lanes: columns,
		enabled: !!scrollRef?.current,
	})

	const virtualizer = !!scrollRef?.current ? screenVirtualizer : windowVirtualizer

	useEffect(() => {
		const [lastItem] = [...virtualizer.getVirtualItems()].reverse()

		if (!lastItem) {
			return
		}

		if (lastItem.index >= holograms.length - 1 && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	}, [hasNextPage, fetchNextPage, holograms.length, isFetchingNextPage, virtualizer.getVirtualItems()])

	return (
		<div
			ref={galleryRef}
			className="hologram-gallery"
			style={{
				height: `${virtualizer.getTotalSize()}px`,
				width: "100%",
				position: "relative",
				maxWidth: "100vw",
				overflow: "hidden",
			}}>
			{virtualizer.getVirtualItems().map((virtualRow, i) => {
				const isLoaderRow = virtualRow.index > holograms.length - 1
				const hologram = holograms[virtualRow.index]

				if (!hologram) {
					return
				}

				return (
					<div
						key={`${virtualRow.index}`}
						ref={virtualizer.measureElement}
						data-index={virtualRow.index}
						style={{
							position: "absolute",
							padding: "5px",
							top: 0,
							left: `${virtualRow.lane * (100 / columns)}%`,
							width: `${100 / columns}%`,
							transform: `translateY(${virtualRow.start - virtualizer?.options.scrollMargin}px)`,
						}}>
						<PreviewCard
							{...card}
							autoplay={animatedGrams.includes(hologram.id)}
							hologram={hologram}
							playlistItemID={playlistItemIDs?.[virtualRow.index]}
							selectable={selectable}
							// if playlistItemIDs are present, use those instead of the hologram id
							selected={isSelected(virtualRow.index)}
							onSelected={() => handleOnSelect(virtualRow.index)}
						/>
						{isLoaderRow ? hasNextPage ? "Loading more..." : "Nothing more to load" : <></>}
					</div>
				)
			})}
		</div>
	)
}
