import { motion } from "framer-motion"

interface DesignMarkProps {
	src: string
	className?: string
	width: number
	height: number
	rotate?: boolean
	delay?: number
}

export function DesignMark({ src, className, width, height, rotate = true, delay }: DesignMarkProps) {
	return (
		<div className={className}>
			<motion.img
				src={src}
				alt=""
				width={width}
				height={height}
				initial={{ marginTop: -100, rotateZ: rotate ? 45 : 0 }}
				animate={{ marginTop: 100, rotateZ: rotate ? -15 : 0 }}
				transition={{
					repeatType: "reverse",
					repeat: Infinity,
					ease: "easeInOut",
					duration: 5,
					delay: delay ?? 0,
				}}
			/>
		</div>
	)
}
