import { classNames } from "lib/classNames"
import Image from "next/image"
import { Logos } from "./HeroSellingPoints"
import UploadButton from "components/UploadButtonHomePage"

export function StartHologramming({}) {
	return (
		<>
			<div className="mt-6 overflow-x-clip px-4 py-6 pb-14 md:mt-12 md:px-16">
				<div className="bg-white-300 bg-holo5-opaque-dark dark:bg-holo5-opaque relative max-w-4xl rounded-lg py-5 pl-6  md:mx-auto md:pl-12">
					<div className="mt-8 mb-4 flex flex-row items-center justify-center">
						<div className="flex flex-col items-center space-y-4">
							<h1 className="dark:holo5 text-4xl font-bold">Start Hologramming.</h1>
							<div className="text-xs md:text-sm">
								With a single line of code, embed your holograms anywhere <br /> on the web. Your website,
								galleries, the metaverse - and beyond.
							</div>
							<div>
								<UploadButton id="create-button-start-hologramming" />
							</div>
						</div>
					</div>
					<Floater type="squiggle" className="absolute -left-[90px] top-5" />
					<Floater type="triangle" className="absolute -bottom-14 left-52 z-10 hidden md:inline-block" />

					<Floater type="triangle-sm" className="absolute right-24 top-28 z-10 hidden md:inline-block" />

					<Floater type="sphere-lg" className="absolute right-32 -top-8 z-10 hidden md:inline-block" />
					<Floater type="sphere-sm" className="absolute -bottom-6 left-1/2 hidden md:inline-block" />

					<Floater type="x" className="absolute bottom-3 -right-16" />
				</div>
				<div className={"mt-6 flex items-center justify-center"}>
					<Logos logos={["unity", "unreal", "blender", "ios", "android"]} size={6} gap={36} />
				</div>
			</div>
		</>
	)
}

interface FloaterArgs {
	type: Floater
	className?: string
}
type Floater = "squiggle" | "triangle-sm" | "x" | "squiggle-sm" | "sphere-lg" | "sphere-sm" | "triangle"
function Floater({ type, className }: FloaterArgs) {
	return (
		<div className={classNames(className!)}>
			{type == "squiggle" && <Image src="/worm-squiggle.png" width="130" height="104" quality={80} alt="" />}

			{type == "squiggle-sm" && (
				<Image src="/worm-floater-sm.png" width="98" height="84" quality={80} alt="" />
			)}
			{type == "triangle" && (
				<Image src="/triangle-floater.png" width="119" height="115" quality={80} alt="" />
			)}
			{type == "triangle-sm" && (
				<Image src="/triangle-floater-sm.png" width="66" height="69" quality={80} alt="" />
			)}
			{type == "sphere-lg" && (
				<Image src="/sphere-floater-lg.png" width="98" height="98" quality={80} alt="" />
			)}
			{type == "sphere-sm" && <Image src="/circle-floater.png" width="35" height="34" quality={80} alt="" />}
			{type == "x" && <Image src="/x-floater.png" width="138" height="98" quality={80} alt="" />}
		</div>
	)
}
