import { EmbedHologram } from "@/lib/hologramProps"
import UploadButton from "components/UploadButtonHomePage"
import { useRef } from "react"
import HologramEmbed from "../embed/HologramEmbed"
import HoloText from "../HoloText"
import HologramBackground from "../Navigation/HologramBackground"
import { DesignMark } from "./DesignMark"
import HeroSellingPoints from "./HeroSellingPoints"

interface BannerProps {
	hologram: EmbedHologram
}

export default function Banner({ hologram }: BannerProps) {
	const wiggleRef = useRef<HTMLDivElement>(null)

	return (
		<div className="relative z-0 -mt-[76px]">
			<div className="pointer-events-none absolute inset-0 z-[1] mt-[76px] flex h-[calc(100vh-76px)] flex-col items-center justify-center gap-2 px-2 text-center md:gap-4 [&>*]:pointer-events-auto">
				<HoloText className="pb-2 text-4xl font-black sm:text-5xl md:text-6xl">
					Goodbye GIFs.
					<br />
					Meet holograms.
				</HoloText>
				<div className="text-shadow mt-4 text-lg text-white md:text-2xl">
					The first holographic sharing platform <br className="hidden md:block" />
					built for 3D creators.
				</div>
				<UploadButton id="create-a-hologram-button-hero" />
				<HologramEmbed
					hologram={hologram}
					dropshadow={true}
					enableVR={false}
					enableAngleStore={true}
					wiggle={true}
					enableOnLookingGlass={false}
					enableIntersectionObserver={false}
					animate={false}
					fullscreen={true}
					className="z-[1] h-full max-h-[350px] lg:max-h-[400px]"
					wiggleContainerRef={wiggleRef}
				/>
			</div>
			<div ref={wiggleRef} className="relative left-0 top-0 h-[75dvh] w-full overflow-hidden">
				<HologramBackground hologram={hologram} blur={8} />
			</div>
			<HeroSellingPoints />
			<div className="pointer-events-none absolute inset-0 pt-[76px]">
				<DesignMark
					src="/home/rounded_x.svg"
					className={`absolute left-[10%] top-[10%] origin-center -translate-x-1/2 -translate-y-1/2 md:left-[20%] md:top-[30%]`}
					width={161}
					height={171}
				/>
				<DesignMark
					src="/home/pyramid.svg"
					className={`absolute left-[80%] top-[14%] origin-center -translate-x-1/2 -translate-y-1/2 md:top-[25%]`}
					width={196}
					height={236}
					delay={0.5}
				/>
				<DesignMark
					src="/home/squiggly.svg"
					className={`absolute left-[82%] top-[45%] origin-center -translate-x-1/2 -translate-y-1/2 md:left-[76%] md:top-[65%]`}
					width={310}
					height={300}
					rotate={false}
				/>
			</div>
		</div>
	)
}
